<template>
  <div class="amui-efficiency-class-bar" :class="classes">
    <div class="amui-efficiency-class-bar__label">
      {{ value }}
    </div>
    <div class="amui-efficiency-class-bar__icon-wrapper">
      <svg-corner-s
        class="amui-efficiency-class-bar__icon amui-efficiency-class-bar__icon--s"
      />
      <svg-corner-m
        class="amui-efficiency-class-bar__icon amui-efficiency-class-bar__icon--m"
      />
      <svg-corner-xl
        class="amui-efficiency-class-bar__icon amui-efficiency-class-bar__icon--xl"
      />
    </div>
  </div>
</template>

<script>
import svgCornerS from './assets/corner-s.svg'
import svgCornerM from './assets/corner-m.svg'
import svgCornerXl from './assets/corner-xl.svg'

export const AMUI_EFFICIENCY_CLASSES = [
  'A+++',
  'A++',
  'A+',
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G'
]

export default {
  name: 'AmuiEfficiencyClassBar',

  components: {
    svgCornerS,
    svgCornerM,
    svgCornerXl
  },

  props: {
    value: {
      type: String,
      required: false
    },
    autoWidth: {
      type: Boolean,
      required: false,
      default: true
    },
    selected: {
      type: Boolean,
      required: false,
      default: false
    },
    small: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  computed: {
    classes() {
      return [
        'amui-efficiency-class-bar--class-' +
          this.value
            .replace('+++', '-ppp')
            .replace('++', '-pp')
            .replace('+', '-p')
            .toLowerCase(),
        {
          'amui-efficiency-class-bar--selected': this.selected,
          'amui-efficiency-class-bar--auto-width': this.autoWidth,
          'amui-efficiency-class-bar--small': this.small
        }
      ]
    }
  }
}
</script>
