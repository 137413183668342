import { formatPrice, formatMileage } from './../../../../../utils/formatter.js'
import legalMixin from './legal.mixin.js'

export default {
  mixins: [legalMixin],

  methods: {
    getLeasingOfferLegalText(offer, grossListPrice, comment = '') {
      return this.getCleanedLegalText(
        this.$t('vdp.offer.configuration.leasing.legal.template', {
          comment,
          grossListPrice: this.$t(
            'vdp.offer.configuration.leasing.legal.grossListPrice',
            { value: formatPrice(grossListPrice, true) }
          ),
          downPayment: this.$t(
            'vdp.offer.configuration.leasing.legal.downPayment',
            { value: formatPrice(offer.downPayment, true) }
          ),
          totalAmount: this.$t(
            'vdp.offer.configuration.leasing.legal.totalAmount',
            { value: formatPrice(offer.totalAmount, true) }
          ),
          termOfContract: this.$t(
            'vdp.offer.configuration.leasing.legal.termOfContract',
            { value: offer.termOfContract }
          ),
          annualMileage: this.$t(
            'vdp.offer.configuration.leasing.legal.annualMileage',
            { value: formatMileage(offer.annualMileage) }
          ),
          netLoanAmount: this.$t(
            'vdp.offer.configuration.leasing.legal.netLoanAmount',
            { value: formatPrice(offer.netLoanAmount, true) }
          ),
          nominalInterestRate: this.$t(
            'vdp.offer.configuration.leasing.legal.nominalInterestRate',
            { value: offer.nominalInterestRate }
          ),
          annualPercentageRate: this.$t(
            'vdp.offer.configuration.leasing.legal.annualPercentageRate',
            { value: offer.annualPercentageRate }
          ),
          additionalCosts: this.getLegalTextForDestinationChargeAndRegistrationFees(
            offer.destinationCharges,
            offer.registrationFees
          ),
          vatInfo: this.$t('vdp.offer.configuration.legal.vatInfo.inclusive')
        })
      )
    }
  }
}
