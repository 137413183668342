<template>
  <a
    :href="vehicleUrl"
    class="am-vehicle-tile"
    :class="{ 'am-vehicle-tile--favored': isFavorite }"
    @click.stop.prevent="$emit('view')"
  >
    <div class="am-vehicle-tile__inner">
      <div>
        <div class="am-vehicle-tile__title">{{ title }}</div>
        <button
          class="am-vehicle-tile__fav"
          :aria-label="
            isFavorite
              ? $t('favorites.vehicle.remove')
              : $t('favorites.vehicle.add')
          "
          @click.stop.prevent="$emit('favour')"
        >
          <amui-icon :name="favoriteIconName"></amui-icon>
        </button>
      </div>
      <div class="am-vehicle-tile__usage-type">
        <amui-chip
          v-if="usageType"
          :label="usageType.label"
          :type="usageType.value === 'NEW' ? 'primary' : 'secondary'"
        />
      </div>
      <div>
        <div class="am-vehicle-tile__image-wrap">
          <amui-lazy-image
            :source="imageSource"
            :alt="imageAlt"
            class="am-vehicle-tile__image"
            @change="onChangeImageWidth"
          />
        </div>
        <div class="am-vehicle-tile__price">
          <span>{{ price }}</span>
          <span v-if="rate">{{
            $t('price.indications.monthly.from2', { price: rate })
          }}</span>
        </div>
        <div class="am-vehicle-tile__features">
          {{ featureList.join(', ') }}
        </div>
        <div class="am-vehicle-tile__efficiency">
          <amui-efficiency-class-icon
            v-if="efficiencyClass && efficiencyText"
            v-model="efficiencyClass"
            small
          />
          <span
            >{{ efficiencyText }}
            <sup>{{ efficiencyTextReferenceNumber }}</sup></span
          >
        </div>
      </div>
    </div>
  </a>
</template>

<script>
import { AmuiIcon } from '@/../ui/components/icon'
import { AmuiLazyImage } from '@/../ui/components/lazy-image'
import { AmuiChip } from '@/../ui/components/chip'
import { AmuiEfficiencyClassIcon } from '@/../ui/components/efficiency-class-icon'

export default {
  name: 'AmVehicleTile',

  components: {
    AmuiIcon,
    AmuiLazyImage,
    AmuiChip,
    AmuiEfficiencyClassIcon
  },

  props: {
    vehicleUrl: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    isFavorite: {
      type: Boolean,
      required: false
    },
    media: {
      type: Array,
      required: true
    },
    price: {
      type: String,
      required: true
    },
    rate: {
      type: String,
      required: false,
      default: null
    },
    features: {
      type: Array,
      required: true,
      default: null
    },
    customerHighlights: {
      type: Array,
      required: false,
      default: () => []
    },
    efficiencyClass: {
      type: String,
      required: false,
      default: null
    },
    efficiencyText: {
      type: String,
      required: false,
      default: null
    },
    efficiencyTextReferenceNumber: {
      type: String,
      default: null
    },
    usageType: {
      type: Object,
      required: false,
      default: null
    }
  },

  data() {
    return {
      imageSource: null,
      imageAlt: null
    }
  },

  computed: {
    favoriteIconName() {
      return this.isFavorite ? 'favorite-filled' : 'favorite'
    },
    featureList() {
      let features = []

      features = features.concat(this.customerHighlights.map(c => c.label))

      const mileage = this.features.find(f => f.id === 'mileage')
      const fuel = this.features.find(f => f.id === 'fuel')

      if (fuel !== undefined) {
        features.unshift(fuel.label)
      }

      if (mileage !== undefined) {
        features.unshift(mileage.label)
      }

      return features
    }
  },

  methods: {
    onChangeImageWidth(width) {
      const media = this.media.length ? this.media[0] : null
      const computedWidth = width * (window.devicePixelRatio || 1)

      if (media) {
        if (media.type === 'cpo') {
          const splittedSegments = media.normal.split('/')
          const filename = splittedSegments.pop()
          this.imageSource =
            splittedSegments.join('/') +
            '/tr:w-' +
            computedWidth +
            '/' +
            filename
          this.imageAlt = media.alt
        } else {
          this.imageSource = media.normal
          this.imageAlt = this.title
        }
      }
    }
  }
}
</script>

<style lang="scss">
@import './vehicle-tile';
</style>
