export const consumptionPropertyList = [
  'wltpValues.consumptionFuelCombined',
  'amNedcValues.consumptionFuelCombined',
  'amNedcValues.consumptionFuelCity',
  'amNedcValues.consumptionFuelOverland',
  'wltpValues.consumptionFuelCombinedWeighted',
  'amNedcValues.consumptionFuelCombinedWeighted',
  'wltpValues.consumptionPowerCombined',
  'amNedcValues.consumptionPowerCombined',
  'wltpValues.consumptionPowerCombinedWeighted',
  'amNedcValues.consumptionPowerCombinedWeighted',
  'wltpValues.electricRange',
  'wltpValues.co2EmissionCombined',
  'amNedcValues.co2EmissionCombined',
  'wltpValues.co2EmissionCombinedWeighted',
  'amNedcValues.co2EmissionCombinedWeighted',
  'emissionSticker',
  'emissionClass'
]

export default {
  consumptionPropertyList
}
