<template>
  <amui-financing-popover-content-layout
    :header="header"
    :body="body"
    :foot-notes="footNotes"
  />
</template>

<script>
import AmuiFinancingPopoverContentLayout from './financing-popover-content-layout.vue'
import { formatPrice, formatMileage } from './../../../../utils/formatter'

export default {
  name: 'AmuiFinancingPopoverContentLeasing',

  components: {
    AmuiFinancingPopoverContentLayout
  },

  props: {
    offer: {
      type: Object,
      required: true
    }
  },

  computed: {
    header() {
      return {
        label: 'Mtl. Leasingrate **',
        value: formatPrice(this.offer.data.grossRate) + ' €'
      }
    },
    // copied over and modified from price calculator
    formattedOfferDetails() {
      const offer = this.offer.data

      let additionalCosts = null

      if (
        offer.destinationCharges === undefined &&
        offer.registrationFees === undefined
      ) {
        additionalCosts = this.$t(
          'ui.priceCalculator.legal.additionalCosts.noInfoDestinationChargesAndRegistrationFees'
        )
      } else if (
        offer.destinationCharges === 0 &&
        offer.registrationFees === 0
      ) {
        additionalCosts = this.$t(
          'ui.priceCalculator.legal.additionalCosts.inclusiveDestinationChargesAndRegistrationFees'
        )
      } else if (offer.destinationCharges > 0 && offer.registrationFees > 0) {
        additionalCosts = this.$t(
          'ui.priceCalculator.legal.additionalCosts.exclusiveDestinationChargesAndRegistrationFees',
          {
            sum: formatPrice(
              offer.destinationCharges + offer.registrationFees,
              true
            )
          }
        )
      } else {
        if (offer.destinationCharges === 0) {
          additionalCosts = this.$t(
            'ui.priceCalculator.legal.additionalCosts.inclusiveDestinationCharges'
          )
        } else if (offer.destinationCharges > 0) {
          additionalCosts = this.$t(
            'ui.priceCalculator.legal.additionalCosts.exclusiveDestinationCharges',
            {
              sum: formatPrice(offer.destinationCharges, true)
            }
          )
        } else {
          // undefined
          additionalCosts = this.$t(
            'ui.priceCalculator.legal.additionalCosts.noInfoDestinationCharges'
          )
        }

        if (offer.registrationFees === 0) {
          additionalCosts += this.$t(
            'ui.priceCalculator.legal.additionalCosts.inclusiveRegistrationFees'
          )
        } else if (offer.registrationFees > 0) {
          additionalCosts += this.$t(
            'ui.priceCalculator.legal.additionalCosts.exclusiveRegistrationFees',
            {
              sum: formatPrice(offer.registrationFees, true)
            }
          )
        } else {
          // undefined
          additionalCosts += this.$t(
            'ui.priceCalculator.legal.additionalCosts.noInfoRegistrationFees'
          )
        }
      }

      return {
        grossListPrice: formatPrice(offer.grossListPrice, true) + ' €',
        bank: offer.comment,
        downPayment: formatPrice(offer.downPayment, true) + ' €',
        totalAmount: formatPrice(offer.totalAmount, true) + ' €',
        termOfContract: offer.termOfContract + ' Monate',
        annualMileage: formatMileage(offer.annualMileage) + ' km',
        netLoanAmount: formatPrice(offer.netLoanAmount, true) + ' €',
        nominalInterestRate: offer.nominalInterestRate + ' %',
        annualPercentageRate: offer.annualPercentageRate + ' %',
        additionalCosts: additionalCosts
      }
    },
    body() {
      const retVal = [
        {
          label: 'UPE',
          value: this.formattedOfferDetails.grossListPrice
        },
        {
          label: 'einmalige Leasingsonderzahlung',
          value: this.formattedOfferDetails.downPayment
        },
        {
          label: 'Gesamtbetrag',
          value: this.formattedOfferDetails.totalAmount
        },
        {
          label: 'Laufzeit',
          value: this.formattedOfferDetails.termOfContract
        },
        {
          label: 'Kilometer p.a.',
          value: this.formattedOfferDetails.annualMileage
        },
        {
          label: 'Nettodarlehensbetrag',
          value: this.formattedOfferDetails.netLoanAmount
        },
        {
          label: 'Sollzinssatz p.a. *',
          value: this.formattedOfferDetails.nominalInterestRate
        },
        {
          label: 'effektiver Jahreszins',
          value: this.formattedOfferDetails.annualPercentageRate
        }
      ]

      if (this.formattedOfferDetails.additionalCosts !== null) {
        retVal.push({
          label: this.formattedOfferDetails.additionalCosts,
          value: null
        })
      }

      return retVal
    },
    footNotes() {
      return [
        '*' +
          ' Der Sollzinssatz ist für die gesamte Vertragslaufzeit gebunden.',
        '**' +
          ' Repräsentatives Beispiel: Vorstehende Angaben stellen zugleich das 2/3-Beispiel gem. § 6a Abs. 3 PAngV dar. ' +
          this.formattedOfferDetails.bank +
          '. Alle Preise inkl. der gesetzlichen MwSt.'
      ]
    }
  }
}
</script>
