import { formatPrice } from './../../../../../utils/formatter.js'

export default {
  methods: {
    getLegalTextForDestinationChargeAndRegistrationFees(
      destinationCharges,
      registrationFees
    ) {
      let textFragments = []

      if (destinationCharges === undefined && registrationFees === undefined) {
        textFragments.push(
          this.$t(
            'vdp.offer.configuration.legal.additionalCosts.noInfoDestinationChargesAndRegistrationFees'
          )
        )
      } else if (destinationCharges === 0 && registrationFees === 0) {
        textFragments.push(
          this.$t(
            'vdp.offer.configuration.legal.additionalCosts.inclusiveDestinationChargesAndRegistrationFees'
          )
        )
      } else if (destinationCharges > 0 && registrationFees > 0) {
        textFragments.push(
          this.$t(
            'vdp.offer.configuration.legal.additionalCosts.exclusiveDestinationChargesAndRegistrationFees',
            {
              sum: formatPrice(destinationCharges + registrationFees, true)
            }
          )
        )
      } else {
        if (destinationCharges === 0) {
          textFragments.push(
            this.$t(
              'vdp.offer.configuration.legal.additionalCosts.inclusiveDestinationCharges'
            )
          )
        } else if (destinationCharges > 0) {
          textFragments.push(
            this.$t(
              'vdp.offer.configuration.legal.additionalCosts.exclusiveDestinationCharges',
              {
                sum: formatPrice(destinationCharges, true)
              }
            )
          )
        } else {
          // undefined
          textFragments.push(
            this.$t(
              'vdp.offer.configuration.legal.additionalCosts.noInfoDestinationCharges'
            )
          )
        }

        if (registrationFees === 0) {
          textFragments.push(
            this.$t(
              'vdp.offer.configuration.legal.additionalCosts.inclusiveRegistrationFees'
            )
          )
        } else if (registrationFees > 0) {
          textFragments.push(
            this.$t(
              'vdp.offer.configuration.legal.additionalCosts.exclusiveRegistrationFees',
              {
                sum: formatPrice(registrationFees, true)
              }
            )
          )
        } else {
          // undefined
          textFragments.push(
            this.$t(
              'vdp.offer.configuration.legal.additionalCosts.noInfoRegistrationFees'
            )
          )
        }
      }

      return textFragments.join(', ')
    },
    getCleanedLegalText(text) {
      return text
        .replace(/(, ){2,}/g, ', ')
        .replace(/(\. ){2,}/g, '. ')
        .trim()
    }
  }
}
