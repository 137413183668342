<template>
  <div
    class="am-checkout-process"
    :class="{ 'am-checkout-process--autouncle': showRating }"
  >
    <button class="am-checkout-process__close" @click="close">
      <amui-icon name="close" />
    </button>
    <div class="am-checkout-process__content">
      <am-checkout-autouncle
        v-if="showRating"
        :vehicle-object="vehicleObject"
      />
      <am-checkout-personal-data
        v-else-if="currentStep === 'personal-data'"
        ref="form"
        :step-number="currentStepNumber"
        :total-steps-number="totalStepsNumber"
        :salutation.sync="personalData.salutation"
        :first-name.sync="personalData.firstName"
        :last-name.sync="personalData.lastName"
        :zip-code.sync="personalData.zipCode"
        @save="onSavePersonalData"
        @error="onError"
      />
      <am-checkout-contact-data
        v-else-if="currentStep === 'contact-data'"
        ref="form"
        :step-number="currentStepNumber"
        :total-steps-number="totalStepsNumber"
        :email.sync="contactData.email"
        :phone.sync="contactData.phone"
        :message.sync="contactData.message"
        :testdrive-date.sync="contactData.testdriveDate"
        :testdrive-time.sync="contactData.testdriveTime"
        :use-testdrive="checkoutType === 'testdrive'"
        @save="onSaveContactData"
        @error="onError"
      />
      <am-checkout-success
        v-else-if="currentStep === 'success'"
        ref="form"
        :vehicle-object="vehicleObject"
        :use-autouncle="useAutouncle"
        :vehicle-title="vehicleTitle"
        :vehicle-price-label="vehiclePriceLabel"
        :vehicle-image="vehicleImage"
        @rate="onRate"
      />
      <am-checkout-selection
        v-else
        ref="form"
        :step-number="currentStepNumber"
        :total-steps-number="totalStepsNumber"
        :checkout-type.sync="checkoutType"
        @save="onSaveCheckoutSelection"
        @error="onError"
      />
    </div>
    <am-checkout-footer
      :back-button-label="backButtonLabel"
      :next-button-label="nextButtonLabel"
      @back="onBack"
      @next="onNext"
      class="am-checkout-process__footer"
    />
  </div>
</template>

<script>
import { AmuiIcon } from '@/../ui/components/icon'
import AmCheckoutFooter from './../checkout-footer/checkout-footer.vue'
import AmCheckoutSelection from './../checkout-selection/checkout-selection.vue'
import AmCheckoutPersonalData from './../checkout-personal-data/checkout-personal-data.vue'
import AmCheckoutContactData from './../checkout-contact-data/checkout-contact-data.vue'
import AmCheckoutSuccess from './../checkout-success/checkout-success.vue'
import AmCheckoutAutouncle from './../checkout-autouncle/checkout-autouncle.vue'
import { mapActions, mapGetters } from 'vuex'
import { saveLandingPageLead, saveTestDriveLead } from '@/app/requests/lead'

import { getGTMTracker } from '@/app/tracking/gtm.js'

const stepTemplates = {
  offer: ['personal-data', 'contact-data', 'success'],
  testdrive: ['personal-data', 'contact-data', 'success']
}

export default {
  name: 'AmCheckoutProcess',

  components: {
    AmuiIcon,
    AmCheckoutFooter,
    AmCheckoutSelection,
    AmCheckoutPersonalData,
    AmCheckoutContactData,
    AmCheckoutSuccess,
    AmCheckoutAutouncle
  },

  props: {
    vehicleObject: {
      type: Object,
      required: true
    },
    vehicleTitle: {
      type: String,
      required: true
    },
    vehiclePriceLabel: {
      type: String,
      required: true
    },
    vehicleImage: {
      type: Object,
      required: false,
      default: null
    }
  },

  data() {
    return {
      gtm: null,
      checkoutType: '',
      personalData: {
        salutation: null,
        firstName: '',
        lastName: '',
        zipCode: ''
      },
      contactData: {
        email: '',
        phone: '',
        message: '',
        testdriveDate: '',
        testdriveTime: null
      },
      steps: [],
      currentStep: null,
      showRating: false
    }
  },

  computed: {
    ...mapGetters('core', {
      companyId: 'companyId',
      getIntegrationByName: 'getIntegrationByName'
    }),
    ...mapGetters('consent', { consentAllowedServices: 'allowedServices' }),
    hasAutouncleConsent() {
      return this.consentAllowedServices.includes('autouncle')
    },
    currentStepNumber() {
      // checkout type selection is step 1
      let step = 1

      const stepIndex = this.steps.findIndex(step => step === this.currentStep)

      if (stepIndex > -1) {
        step = stepIndex + 2
      }

      return step
    },
    totalStepsNumber() {
      // checkout type selection is step 1
      // thanks page is not part of step count
      let steps = this.steps.length

      if (this.steps.length === 0) {
        steps = Math.max(
          ...Object.keys(stepTemplates).map(type => stepTemplates[type].length)
        )
      }

      return steps
    },
    useAutouncle() {
      return (
        this.getIntegrationByName('autouncle-trade-in') !== undefined &&
        this.hasAutouncleConsent
      )
    },
    backButtonLabel() {
      let label = null

      if (this.currentStep === 'success') {
        label = this.useAutouncle
          ? this.$t('checkout.cta.close')
          : this.$t('checkout.cta.finished')
      } else {
        label =
          this.currentStep === null
            ? this.$t('checkout.cta.abort')
            : this.$t('checkout.cta.back')
      }

      return label
    },
    nextButtonLabel() {
      let label = null

      if (this.currentStep === 'success') {
        if (this.useAutouncle) {
          label = this.$t('checkout.cta.rating')
        }
      } else {
        label =
          this.currentStepNumber === this.totalStepsNumber
            ? this.$t('checkout.cta.submit')
            : this.$t('checkout.cta.next')
      }

      return label
    },
    leadVehicleDetails() {
      const details = {
        title: '',
        bonuses: '',
        equipment: '',
        mileage: '',
        interior: '',
        color: '',
        url: '',
        vin: '',
        keys: {
          type: '',
          mobileAdId: '',
          mobileSellerId: '',
          vehicleId: ''
        }
      }

      if (this.vehicleObject !== null) {
        Object.assign(details, {
          title: this.vehicleTitle,
          mileage: this.vehicleObject.mileage,
          interior: this.vehicleObject.interiorColor,
          color: this.vehicleObject.exteriorColor,
          url: location.origin + this.vehicleUrl,
          vin: this.vehicleObject.vin,
          keys: {
            type: 'BTO',
            mobileAdId: this.vehicleObject.mobileAdId,
            mobileSellerId: this.vehicleObject.mobileSellerId,
            vehicleId: this.vehicleObject.internalNumber
          }
        })
      }

      return details
    },
    vehicleUrl() {
      let url = null

      if (this.vehicleObject?.mobileAdId) {
        url = this.$router.resolve({
          name: 'detail',
          params: {
            id: this.vehicleObject.mobileAdId
          }
        }).href
      }

      return url
    },
    landingPageLeadPayload() {
      return {
        type: 'BTO',
        contact: {
          gender: this.personalData.salutation,
          name: this.personalData.firstName + ' ' + this.personalData.lastName,
          email: this.contactData.email,
          phone: this.contactData.phone,
          freeFormText: this.contactData.message,
          zipcode: this.personalData.zipCode,
          ip: '',
          qa: []
        },
        vehicle: this.leadVehicleDetails,
        dealerId: this.vehicleObject.mobileSellerId
      }
    },
    testDriveLeadPayload() {
      return {
        contact: {
          gender: this.personalData.salutation,
          name: this.personalData.firstName + ' ' + this.personalData.lastName,
          email: this.contactData.email,
          phone: this.contactData.phone,
          freeFormText: this.contactData.message,
          zipcode: this.personalData.zipCode
        },
        vehicleTitle: this.vehicleTitle,
        vehicleId: this.vehicleObject.internalNumber,
        dealerId: this.vehicleObject.mobileSellerId,
        date: this.contactData.testdriveDate + 'T00:00:00.000Z',
        time: this.contactData.testdriveTime,
        purchasePlanned: 'NONE'
      }
    }
  },

  watch: {
    checkoutType() {
      this.setSteps()
    }
  },

  mounted() {
    this.gtm = getGTMTracker()
  },

  methods: {
    ...mapActions('core', ['addNotification']),
    ...mapActions('tracking', ['trackEvent']),
    close() {
      this.$emit('close')
    },
    onBack() {
      if (this.currentStep === null || this.currentStep === 'success') {
        this.close()
      } else {
        this.setPreviousStep()
      }
    },
    onNext() {
      this.$refs.form.submit()
    },
    onSavePersonalData(data) {
      this.personalData = data
      this.handleNextStep()
    },
    onSaveContactData(data) {
      this.contactData = data
      this.handleNextStep()
    },
    onSaveCheckoutSelection(checkoutType) {
      this.setSteps()
      this.checkoutType = checkoutType
      this.handleNextStep()
    },
    onRate() {
      this.showRating = true

      const payload = {
        provider: 'AutoUncle',
        context: 'Checkout'
      }

      this.gtm.trackEvent({
        name: 'trade_in_integration_started',
        payload
      })
    },
    onError() {
      this.addNotification({
        message: this.$t('checkout.error.general'),
        type: 'error'
      })
    },
    setSteps() {
      this.steps =
        stepTemplates[this.checkoutType] !== undefined
          ? stepTemplates[this.checkoutType]
          : []
    },
    handleNextStep() {
      if (this.currentStepNumber === this.totalStepsNumber) {
        this.saveLead()
          .then(() => {
            this.setNextStep()
          })
          .catch(() => {
            // TODO: error case
          })
      } else {
        this.setNextStep()
      }
    },
    setNextStep() {
      let step = this.currentStep

      if (this.currentStep !== null) {
        const currentStepIndex = this.steps.findIndex(
          s => s === this.currentStep
        )

        if (currentStepIndex + 1 <= this.steps.length) {
          step = this.steps[currentStepIndex + 1]
        }
      } else if (this.steps.length) {
        step = this.steps[0]
      }

      this.currentStep = step
    },
    setPreviousStep() {
      let step = this.currentStep

      if (this.currentStep !== null) {
        const currentStepIndex = this.steps.findIndex(
          s => s === this.currentStep
        )

        if (currentStepIndex > -1) {
          if (currentStepIndex === 0) {
            step = null
          } else {
            step = this.steps[currentStepIndex - 1]
          }
        }
      }

      this.currentStep = step
    },
    saveLead() {
      if (this.checkoutType === 'testdrive') {
        return saveTestDriveLead(
          this.companyId,
          this.testDriveLeadPayload
        ).then(() => {
          this.$emit('lead', {
            checkoutType: this.checkoutType
          })
        })
      } else {
        return saveLandingPageLead(
          this.companyId,
          this.landingPageLeadPayload
        ).then(() => {
          this.$emit('lead', {
            checkoutType: this.checkoutType
          })
        })
      }
    }
  }
}
</script>
