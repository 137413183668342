<template>
  <form class="am-checkout-contact-data" autocomplete="on">
    <div>
      <amui-chip
        :label="stepNumber + '/' + totalStepsNumber"
        type="secondary"
      />
    </div>
    <amui-headline
      :text="$t('checkout.contactData.headline')"
      html-tag="div"
      looks-like="h5"
    />
    <div>
      <div class="am-checkout-contact-data__field-headline">
        {{ $t('checkout.contactData.email.headline') }}
        <span>– {{ $t('checkout.contactData.email.subline') }}</span>
      </div>
      <div>
        <amui-input
          v-model="formData.email"
          :label="$t('checkout.contactData.email.label')"
          :invalid="invalid.email"
          viewport="s"
          autocomplete="email"
          @input="onInputEmail"
        />
      </div>
    </div>
    <div>
      <div class="am-checkout-contact-data__field-headline">
        {{ $t('checkout.contactData.phone.headline')
        }}<span> – {{ $t('checkout.contactData.phone.subline') }}</span>
      </div>
      <div>
        <amui-input
          v-model="formData.phone"
          :label="$t('checkout.contactData.phone.label')"
          :invalid="invalid.phone"
          viewport="s"
          autocomplete="tel"
          @keydown="onKeyPressPhone"
          @paste="onPastePhone"
          @input="onInputPhone"
        />
      </div>
    </div>
    <div>
      <div class="am-checkout-contact-data__field-headline">
        {{ $t('checkout.contactData.message.headline') }}
      </div>
      <div>
        <amui-textarea
          v-model="formData.message"
          :placeholder="$t('checkout.contactData.message.label')"
          viewport="s"
          @input="onInputMessage"
        ></amui-textarea>
      </div>
    </div>
    <div v-if="useTestdrive">
      <div class="am-checkout-contact-data__field-headline">
        {{ $t('checkout.contactData.testdriveDate.headline') }}
      </div>
      <div>
        <amui-input
          v-model="formData.testdriveDate"
          type="date"
          :label="$t('checkout.contactData.testdriveDate.label')"
          :invalid="invalid.testdriveDate"
          viewport="s"
          @input="onInputTestdriveDate"
        />
      </div>
    </div>
    <div v-if="useTestdrive">
      <div class="am-checkout-contact-data__field-headline">
        {{ $t('checkout.contactData.testdriveTime.headline') }}
      </div>
      <div>
        <amui-select
          :label="$t('checkout.contactData.testdriveTime.label')"
          v-model="formData.testdriveTime"
          :options="testdriveTimeOptions"
          :invalid="invalid.testdriveTime"
          hide-label-when-selected
          viewport="s"
          @change="onChangeTestdriveTime"
        ></amui-select>
      </div>
    </div>

    <div>
      <amui-checkbox
        v-model="formData.hasConfirmedContact"
        :invalid="invalid.hasConfirmedContact"
        viewport="s"
        @change="invalid.hasConfirmedContact = false"
        ><span slot="label">{{
          $t('checkout.contactData.contactConfirmation.label')
        }}</span></amui-checkbox
      >
    </div>
    <div class="am-checkout-contact-data__confirmation">
      <amui-checkbox
        v-model="formData.hasConfirmedTerms"
        :invalid="invalid.hasConfirmedTerms"
        viewport="s"
        @change="invalid.hasConfirmedTerms = false"
      >
        <amui-i18n
          slot="label"
          :term="
            $t(
              'checkout.contactData.termsConfirmation.label' +
                (termsAndConditionsUrl ? '1' : '2')
            )
          "
          tag="span"
        >
          <a
            slot="terms"
            :href="termsAndConditionsUrl"
            target="_blank"
            rel="nofollow noopener"
            >{{ $t('checkout.contactData.termsConfirmation.terms') }}</a
          >
          <a
            slot="privacy"
            :href="privacyPolicyUrl"
            target="_blank"
            rel="nofollow noopener"
            >{{ $t('checkout.contactData.termsConfirmation.privacy') }}</a
          >
        </amui-i18n>
      </amui-checkbox>
    </div>
  </form>
</template>

<script>
import { AmuiHeadline } from '@/../ui/components/headline'
import { AmuiSelect } from '@/../ui/components/select'
import { AmuiInput } from '@/../ui/components/input'
import { AmuiTextarea } from '@/../ui/components/textarea'
import { AmuiCheckbox } from '@/../ui/components/checkbox'
import { AmuiChip } from '@/../ui/components/chip'
import { AmuiI18n } from '@/../ui/components/i18n'
import { mapState } from 'vuex'

export default {
  name: 'AmCheckoutPersonalData',

  components: {
    AmuiHeadline,
    AmuiSelect,
    AmuiInput,
    AmuiTextarea,
    AmuiChip,
    AmuiCheckbox,
    AmuiI18n
  },

  props: {
    stepNumber: {
      type: Number,
      required: true
    },
    totalStepsNumber: {
      type: Number,
      required: true
    },
    email: {
      type: String,
      required: false,
      default: ''
    },
    phone: {
      type: String,
      required: false,
      default: ''
    },
    message: {
      type: String,
      required: false,
      default: ''
    },
    useTestdrive: {
      type: Boolean,
      required: false,
      default: false
    },
    testdriveDate: {
      type: String,
      required: false,
      default: ''
    },
    testdriveTime: {
      type: String,
      required: false,
      default: ''
    }
  },

  data() {
    return {
      formData: {
        email: this.email,
        phone: this.phone,
        message: this.message,
        testdriveDate: this.testdriveDate,
        testdriveTime: this.testdriveTime,
        hasConfirmedContact: false,
        hasConfirmedTerms: false
      },
      invalid: {
        email: false,
        phone: false,
        testdriveDate: false,
        testdriveTime: false,
        hasConfirmedContact: false,
        hasConfirmedTerms: false
      },
      testdriveTimeOptions: [
        {
          label: this.$t('checkout.contactData.testdriveTime.options.morning'),
          value: 'MORNING'
        },
        {
          label: this.$t('checkout.contactData.testdriveTime.options.noon'),
          value: 'NOON'
        },
        {
          label: this.$t('checkout.contactData.testdriveTime.options.evening'),
          value: 'EVENING'
        }
      ]
    }
  },
  computed: {
    ...mapState('core', {
      rawVehicleMarketCompanyData: 'rawVehicleMarketCompanyData'
    }),
    termsAndConditionsUrl() {
      let url = null

      if (this.rawVehicleMarketCompanyData?.termsAndConditionsLink) {
        url = this.rawVehicleMarketCompanyData.termsAndConditionsLink
      }

      return url
    },
    privacyPolicyUrl() {
      let url = null

      if (this.rawVehicleMarketCompanyData?.privacyPolicyLink) {
        url = this.rawVehicleMarketCompanyData.privacyPolicyLink
      }

      return url
    }
  },
  methods: {
    submit() {
      if (this.validate()) {
        this.$emit('save', Object.assign({}, this.formData))
      } else {
        this.$emit('error')
      }
    },
    validateEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ //eslint-disable-line
      return re.test(email)
    },
    validate() {
      this.invalid.email =
        this.formData.email.length === 0 ||
        !this.validateEmail(this.formData.email)

      this.invalid.phone = this.formData.phone.length === 0
      if (this.useTestdrive) {
        this.invalid.testdriveDate = this.formData.testdriveDate.length === 0
        this.invalid.testdriveTime = this.formData.testdriveTime === null
      }

      this.invalid.hasConfirmedContact =
        this.formData.hasConfirmedContact === false
      this.invalid.hasConfirmedTerms = this.formData.hasConfirmedTerms === false

      return !Object.keys(this.invalid).some(key => this.invalid[key])
    },
    onInputEmail() {
      this.$emit('update:email', this.formData.email)
      this.invalid.email = false
    },
    onPastePhone(event) {
      const originData = event.clipboardData.getData('text')
      const cleanedData = originData.replace(/[^0-9]/g, '')

      if (originData !== cleanedData) {
        event.preventDefault()
      }
    },
    onKeyPressPhone(event) {
      if (
        !['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(event.key)
      ) {
        if (
          !['Backspace', 'Tab'].includes(event.key) &&
          !(['x', 'c', 'v'].includes(event.key) && event.metaKey === true)
        ) {
          event.preventDefault()
        }
      }
    },
    onInputPhone() {
      this.$emit('update:phone', this.formData.phone)
      this.invalid.phone = false
    },
    onInputMessage() {
      this.$emit('update:message', this.formData.message)
      this.invalid.message = false
    },
    onInputTestdriveDate() {
      this.$emit('update:testdriveDate', this.formData.testdriveDate)
      this.invalid.testdriveDate = false
    },
    onChangeTestdriveTime() {
      this.$emit('update:testdriveTime', this.formData.testdriveTime)
      this.invalid.testdriveTime = false
    }
  }
}
</script>
