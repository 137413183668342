<template>
  <div class="am-vdp-footer">
    <div>
      <div
        v-if="!isAdMode"
        class="am-vdp-footer__nav"
        :class="{
          'am-vdp-footer__nav--open': isOpenNavigation,
          'am-vdp-footer__nav--menu': favorites
        }"
      >
        <div>
          <button @click="toggleNavigation">
            <amui-icon name="more-vert" />
          </button>
          <ul>
            <li v-if="favorites" @click="$emit('favorites')">
              <amui-icon name="favorite">
                <amui-badge :label="favorites" />
              </amui-icon>
              <span>Favoriten</span>
            </li>
            <li @click="$emit('search')">
              <amui-icon name="tune-simple"></amui-icon>
              <span>Alle Fahrzeuge</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="am-vdp-footer__content">
        <div class="am-vdp-footer__vehicle-detail">
          <div v-if="imgSource" class="am-vdp-footer__image">
            <img :src="imgSource" :alt="imageAlt" width="72" height="48" />
          </div>
          <div class="am-vdp-footer__title-wrapper">
            <div class="am-vdp-footer__title">
              {{ vehicleTitle }}
            </div>
            <div class="am-vdp-footer__price">
              {{ vehiclePriceLabel }}
            </div>
          </div>
        </div>
        <div>
          <amui-button
            :label="$t('vdp.cta.label')"
            @click.native="$emit('request')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { AmuiButton } from '@/../ui/components/button'
import { AmuiIcon } from '@/../ui/components/icon'
import { AmuiBadge } from '@/../ui/components/badge'
import { getImageQualityParams } from './../../../../../../utils/image'

export default {
  name: 'AmVehicleDetailPageFooter',

  components: {
    AmuiButton,
    AmuiIcon,
    AmuiBadge
  },

  props: {
    vehicleTitle: {
      type: String,
      required: true
    },
    vehiclePriceLabel: {
      type: String,
      required: true
    },
    vehicleImage: {
      type: Object,
      required: false,
      default: null
    },
    favorites: {
      type: Number,
      required: false,
      default: 0
    },
    isAdMode: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data() {
    return {
      isOpenNavigation: false,
      imgSource: null
    }
  },

  computed: {
    imageAlt() {
      let alt = null

      if (this.vehicleImage?.alt) {
        alt = this.vehicleImage.alt
      }

      return alt
    }
  },

  watch: {
    vehicleImage() {
      this.setVehicleImageSource()
    }
  },

  mounted() {
    this.setVehicleImageSource()
  },

  methods: {
    setVehicleImageSource() {
      let source = null

      if (this.vehicleImage?.source) {
        source = this.getResolvedImageSource(this.vehicleImage.source, 72)
      }

      this.imgSource = source
    },
    toggleNavigation() {
      this.isOpenNavigation = !this.isOpenNavigation
    },
    getResolvedImageSource(source, width) {
      const { pixelRatio } = getImageQualityParams()
      const splittedSegments = source.split('/')
      const filename = splittedSegments.pop()
      const resolvedSource =
        splittedSegments.join('/') +
        '/tr:w-' +
        width * pixelRatio +
        ',h-' +
        ((width * 2) / 3) * pixelRatio +
        ',fo-auto/' +
        filename

      return resolvedSource
    }
  }
}
</script>

<style></style>
