<template>
  <div class="am-vehicle-player-image-stage">
    <img :src="source" draggable="false" :alt="alt" />
  </div>
</template>

<script>
export default {
  name: 'AmVehiclePlayerImageStage',

  props: {
    source: {
      type: String,
      required: false,
      default: null
    },
    alt: {
      type: String,
      required: false,
      default: null
    }
  }
}
</script>
