<template>
  <div class="amui-image" :class="classes">
    <picture>
      <source
        v-for="(source, index) in pictureSources"
        :key="index"
        :media="source.media"
        :srcset="source.srcset"
      />
      <img
        v-if="imgSrc"
        draggable="false"
        class="amui-image__image"
        :src="imgSrc"
        :alt="alt"
      />
    </picture>
  </div>
</template>

<script>
export default {
  name: 'AmuiImage',

  props: {
    sources: {
      type: Array,
      required: true,
      validator(sources) {
        return sources.every(
          source => 'media' in source && 'srcset' in source && source.srcset
        )
      }
    },
    fallbackSource: {
      type: String,
      required: false,
      default: null
    },
    ratio: {
      type: String,
      required: false,
      default: null,
      validator: ratio => {
        return ['3:2', '3:1'].includes(ratio)
      }
    },
    alt: {
      type: String,
      required: false,
      default: null
    },
    objectFit: {
      type: String,
      required: false,
      default: null,
      validator(value) {
        return ['cover', 'contain'].includes(value)
      }
    }
  },

  computed: {
    pictureSources() {
      return this.sources.filter(
        source => source.media !== undefined && source.srcset !== undefined
      )
    },
    imgSrc() {
      return this.fallbackSource !== null
        ? this.fallbackSource
        : this.pictureSources.length > 0
        ? this.pictureSources[0].srcset
        : null
    },
    classes() {
      return {
        'amui-image--3-1': this.ratio !== null && this.ratio === '3:1',
        'amui-image--3-2': this.ratio !== null && this.ratio === '3:2',
        'amui-image--cover':
          this.objectFit !== null && this.objectFit === 'cover',
        'amui-image--contain':
          this.objectFit !== null && this.objectFit === 'contain'
      }
    }
  }
}
</script>
