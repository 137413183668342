<template>
  <div class="am-openinghours">
    <div class="am-openinghours__headline">
      {{ $t('vdp.openinghours.headline') }}
    </div>
    <div
      v-for="location in enrichedLocations"
      :key="location.label"
      class="am-openinghours__location"
      :class="{
        'am-openinghours__location--open': location.isOpen
      }"
    >
      <div class="am-openinghours__row">
        <div>{{ location.label }}</div>
        <div>
          {{
            location.isOpen
              ? $t('vdp.openinghours.opened')
              : $t('vdp.openinghours.closed')
          }}
        </div>
      </div>
      <div
        v-for="period in location.periods"
        :key="period.weekdays"
        class="am-openinghours__row"
      >
        <div>{{ period.weekdays }}</div>
        <div>{{ period.timeRange }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AmOpeninghours',

  props: {
    timezone: {
      type: String,
      required: true
    },
    locations: {
      type: Array,
      required: false,
      default: () => []
    }
  },

  computed: {
    enrichedLocations() {
      return this.locations.map(location => {
        const regularHoursPeriods = location.regularHours?.periods || []

        return {
          label: location.departmentDisplayName,
          isOpen: this.getOpenStateByPeriods(regularHoursPeriods),
          periods: this.getEnrichedRegularHoursByPeriods(regularHoursPeriods)
        }
      })
    }
  },
  methods: {
    getOpenStateByPeriods(periods) {
      let isOpen = false

      if (Array.isArray(periods)) {
        let currentDate = new Date()

        currentDate.toLocaleString('de-DE', {
          timeZone: this.timezone
        })

        const weekdayIndex = currentDate.getDay()
        const hours = currentDate.getHours()
        const minutes = currentDate.getMinutes()

        const compareTime = parseInt(
          hours + '' + minutes.toString().padStart(2, 0)
        )

        const foundPeriod = periods.find(period => {
          const periodOpenTime = parseInt(
            period.openTime.hours +
              '' +
              period.openTime.minutes.toString().padStart(2, 0)
          )
          const periodCloseTime = parseInt(
            period.closeTime.hours +
              '' +
              period.closeTime.minutes.toString().padStart(2, 0)
          )

          return (
            weekdayIndex >= period.openDay &&
            weekdayIndex <= period.closeDay &&
            compareTime >= periodOpenTime &&
            compareTime <= periodCloseTime
          )
        })

        isOpen = foundPeriod !== undefined
      }

      return isOpen
    },
    getEnrichedRegularHoursByPeriods(periods) {
      return this.getGroupedPeriods(periods).map(o => {
        let weekdays

        if (o.group.openDayFrom !== o.group.openDayTo) {
          weekdays = this.$t('vdp.openinghours.weekdayRange', {
            from: this.translateWeekday(o.group.openDayFrom),
            to: this.translateWeekday(o.group.openDayTo)
          })
        } else {
          weekdays = this.translateWeekday(o.group.openDayFrom)
        }

        const timeRange = this.$t('vdp.openinghours.timeRange', {
          from:
            o.data.openTime.hours.toString().padStart(2, '0') +
            ':' +
            o.data.openTime.minutes.toString().padStart(2, '0'),
          to:
            o.data.closeTime.hours.toString().padStart(2, '0') +
            ':' +
            o.data.closeTime.minutes.toString().padStart(2, '0')
        })

        return {
          weekdays,
          timeRange
        }
      })
    },
    getGroupedPeriods(periods) {
      let result = []

      if (Array.isArray(periods)) {
        const periodsCopy = [...periods]

        periodsCopy.sort((a, b) => {
          if (a.openDay < b.openDay) {
            return -1
          }
          if (a.openDay > b.openDay) {
            return 1
          }
          return 0
        })

        periodsCopy.forEach(period => {
          const prevResultItem =
            result.length > 0 ? result[result.length - 1] : null

          let openDayFrom = period.openDay
          let openDayTo = period.openDay

          // group by openDay with same time ranges
          if (prevResultItem !== null) {
            // needs to be same time range and also same day range
            const prevKey =
              prevResultItem.data.openTime.hours +
              '' +
              prevResultItem.data.openTime.minutes +
              '' +
              prevResultItem.data.closeTime.hours +
              '' +
              prevResultItem.data.closeTime.minutes +
              '' +
              prevResultItem.data.closeDay -
              prevResultItem.data.openDay

            const currentKey =
              period.openTime.hours +
              '' +
              period.openTime.minutes +
              '' +
              period.closeTime.hours +
              '' +
              period.closeTime.minutes +
              '' +
              period.closeDay -
              period.openDay

            if (
              prevKey === currentKey &&
              period.openDay - 1 === prevResultItem.group.openDayTo
            ) {
              prevResultItem.group.openDayTo++
              return
            }
          }

          result.push({
            group: {
              openDayFrom,
              openDayTo
            },
            data: period
          })
        })
      }

      return result
    },
    translateWeekday(index) {
      const map = {
        0: this.$t('vdp.openinghours.weekdays.sunday'),
        1: this.$t('vdp.openinghours.weekdays.monday'),
        2: this.$t('vdp.openinghours.weekdays.tuesday'),
        3: this.$t('vdp.openinghours.weekdays.wednesday'),
        4: this.$t('vdp.openinghours.weekdays.thursday'),
        5: this.$t('vdp.openinghours.weekdays.friday'),
        6: this.$t('vdp.openinghours.weekdays.saturday')
      }

      return map[index]
    }
  }
}
</script>
