<template>
  <div class="am-vehicle-player-thumbnails" :class="classes">
    <div class="am-vehicle-player-thumbnails__items" ref="items">
      <div
        v-for="(item, i) in enrichedItems"
        :key="'image-' + i"
        ref="item"
        class="am-vehicle-player-thumbnails__item"
      >
        <am-vehicle-player-thumbnail-image
          v-if="item.data.type === 'image'"
          :sources="item.data.imageSources"
          :fallback-source="item.data.fallbackImageSource"
          :alt="item.data.alt"
          :active="value === item.id"
          @click.native="onClickThumbnail(item)"
        ></am-vehicle-player-thumbnail-image>
      </div>
    </div>
  </div>
</template>

<script>
import AmVehiclePlayerThumbnailImage from './components/thumbnail-image/vehicle-player-thumbnail-image.vue'

export default {
  name: 'AmVehiclePlayerThumbnails',

  components: {
    AmVehiclePlayerThumbnailImage
  },

  model: {
    event: 'change',
    prop: 'value'
  },

  props: {
    items: {
      type: Array,
      required: false,
      default: () => []
    },
    value: {
      type: String,
      required: false,
      default: null
    }
  },

  data() {
    return {
      hasGradientLeft: false,
      hasGradientRight: false,
      isDragging: false,
      dragStartX: 0,
      scrollLeft: 0
    }
  },

  computed: {
    classes() {
      return {
        'am-vehicle-player-thumbnails--gradient-left': this.hasGradientLeft,
        'am-vehicle-player-thumbnails--gradient-right': this.hasGradientRight,
        'am-vehicle-player-thumbnails--dragging': this.isDragging
      }
    },
    enrichedItems() {
      return this.items.map(item => {
        let resolvedItem

        if (item.type === 'gallery' && item.data.type === 'image') {
          resolvedItem = Object.assign({}, item, {
            data: Object.assign({}, item.data, {
              imageSources: [
                {
                  media: 'screen and (min-width: 768px)',
                  srcset: [
                    this.getResolvedImageSource(item.data.source, 90),
                    this.getResolvedImageSource(item.data.source, 180) + ' 2x'
                  ].join(', ')
                },
                {
                  media: 'screen and (min-width: 320px)',
                  srcset: [
                    this.getResolvedImageSource(item.data.source, 72),
                    this.getResolvedImageSource(item.data.source, 144) + ' 2x'
                  ].join(', ')
                }
              ],
              fallbackImageSource: this.getResolvedImageSource(
                item.data.source,
                72
              )
            })
          })
        } else {
          resolvedItem = item
        }

        return resolvedItem
      })
    }
  },

  watch: {
    value() {
      this.$nextTick(() => {
        this.adjustScrollPosition()
      })
    }
  },

  created() {
    this.activeItemId = this.value
  },

  mounted() {
    this.addScrollListener()
    this.setGradients()
    this.addMouseListener()
  },

  beforeDestroy() {
    this.removeScrollListener()
    this.removeMouseListener()
  },

  methods: {
    addMouseListener() {
      const slider = this.$refs.items
      slider.addEventListener('mousedown', this.mouseDown)
      slider.addEventListener('mouseleave', this.mouseLeave)
      slider.addEventListener('mouseup', this.mouseUp)
      slider.addEventListener('mousemove', this.mouseMove)
    },
    removeMouseListener() {
      const slider = this.$refs.items
      slider.removeEventListener('mousedown', this.mouseDown)
      slider.removeEventListener('mouseleave', this.mouseLeave)
      slider.removeEventListener('mouseup', this.mouseUp)
      slider.removeEventListener('mousemove', this.mouseMove)
    },
    addScrollListener() {
      this.$refs.items.addEventListener('scroll', this.setGradients)
    },
    removeScrollListener() {
      this.$refs.items.removeEventListener('scroll', this.setGradients)
    },
    setGradients() {
      const el = this.$refs.items
      this.hasGradientLeft = el.scrollLeft !== 0
      this.hasGradientRight =
        Math.ceil(el.scrollLeft + el.offsetWidth) < el.scrollWidth
    },
    getResolvedImageSource(source, width) {
      const splittedSegments = source.split('/')
      const filename = splittedSegments.pop()
      const resolvedSource =
        splittedSegments.join('/') +
        '/tr:w-' +
        width +
        ',h-' +
        (width * 2) / 3 +
        ',fo-auto/' +
        filename

      return resolvedSource
    },
    onClickThumbnail(item) {
      this.$emit('change', item.id)
    },
    adjustScrollPosition() {
      let scrollLeftDiff = null
      const itemIndex = this.items.findIndex(item => item.id === this.value)
      const wrapperWidth = this.$el.offsetWidth
      const itemsRef = this.$refs.items
      const itemRef = this.$refs.item[itemIndex]

      if (
        itemRef.offsetLeft + itemRef.offsetWidth >
        wrapperWidth + itemsRef.scrollLeft
      ) {
        scrollLeftDiff = Math.abs(
          itemRef.offsetLeft - wrapperWidth + itemRef.offsetWidth
        )
      } else if (itemsRef.scrollLeft > itemRef.offsetLeft) {
        scrollLeftDiff = itemRef.offsetLeft
      }

      if (scrollLeftDiff !== null) {
        this.$refs.items.scrollTo({
          left: scrollLeftDiff,
          top: 0,
          behavior: 'smooth'
        })
      }
    },
    mouseDown(e) {
      const slider = this.$refs.items
      this.isDragging = true
      this.dragStartX = e.pageX - slider.offsetLeft
      this.scrollLeft = slider.scrollLeft
    },
    mouseLeave() {
      this.isDragging = false
    },
    mouseUp() {
      this.isDragging = false
    },
    mouseMove(e) {
      if (this.isDragging) {
        e.preventDefault()
        const slider = this.$refs.items
        const left =
          this.scrollLeft - (e.pageX - slider.offsetLeft - this.dragStartX)
        slider.scrollTo({
          left,
          behavior: 'smooth'
        })
      }
    }
  }
}
</script>

<style></style>
