<template>
  <div class="amui-efficiency-class-bars">
    <div
      class="amui-efficiency-class-bars__row"
      v-for="efficiencyClass in efficiencyClasses"
      :key="efficiencyClass"
    >
      <div>
        <amui-efficiency-class-bar
          :value="efficiencyClass"
          :auto-width="false"
          class="amui-efficiency-class-bars__bar"
        />
      </div>
      <amui-efficiency-class-bar
        v-if="efficiencyClass === selectedEfficiencyClass"
        :value="efficiencyClass"
        :auto-width="false"
        selected
        class="amui-efficiency-class-bars__selected-bar"
      />
    </div>
  </div>
</template>
<script>
import { AMUI_EFFICIENCY_CLASSES } from './components/efficiency-class-bar/efficiency-class-bar'
import { AmuiEfficiencyClassBar } from './components/efficiency-class-bar'

export default {
  name: 'AmuiEfficiencyClassBars',

  components: {
    AmuiEfficiencyClassBar
  },

  data() {
    return {
      efficiencyClasses: AMUI_EFFICIENCY_CLASSES
    }
  },
  props: {
    efficiencyClass: {
      type: String,
      required: false
    }
  },
  computed: {
    selectedEfficiencyClass() {
      return this.efficiencyClass
    }
  }
}
</script>
